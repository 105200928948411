<!-- 有害信息举报 -->
<template>
  <div class="harmful">
    <!-- <div class="fontOne">{{ '有害信息举报' }}</div> -->
    <!-- 表单 -->
    <div class="biaodan">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="信息标题：" prop="state2">
          <div class="flex biaotimu">
            <el-input
              class="inline-input"
              v-model.trim="ruleForm.state2"
              placeholder="填写标题"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="危害类别：" prop="wiehai">
          <div class="weihai">
            <el-select v-model="ruleForm.wiehai" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="被举报平台：" prop="pintai">
          <div class="flex biaotimu">
            <el-input
              class="inline-input"
              v-model.trim="ruleForm.pintai"
              placeholder="请输入平台名称"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="举报内容：" prop="neirong">
          <div class="flex biaotimu">
            <el-input
              class="inline-input"
              v-model.trim="ruleForm.neirong"
              placeholder="请描述举报问题"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="页面链接：">
          <div class="flex biaotimu">
            <el-input
              class="inline-input"
              v-model.trim="ruleForm.lianjie"
              placeholder="请输入页面网址"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="举报地址：">
          <div class="flex biaotimu">
            <el-input
              class="inline-input"
              v-model.trim="ruleForm.address"
              placeholder="请输入举报地址"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="举报材料：" :required="true">
          <div>
            <el-upload
              :action="$store.state.uploadurl + '/api/common/upload_public'"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="chenggong"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item label="上传附件："  :required="true">
          <div class="tuodong">
            <el-upload
              class="upload-demo"
              drag
              :action="$store.state.uploadurl + '/api/common/upload_public'"
              multiple
              :on-success="erhao"
              :on-remove="shanchu"
              :before-upload="xianzhi"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                支持上传后缀为：.doc、.docx、.xls、.xlsx、.pdf文件,且不超过20M
              </div>
              <div class="el-upload__tip" slot="tip">
                Excel文件单元格内容避免使用"=" "+" "-"
                或@开头，以免下载查看时，造成命令注入风险
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div class="flex anniu">
        <el-button type="primary" @click="tijiaoBtn('ruleForm')"
          >确定</el-button
        >
        <el-button @click="chongzhi">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/youhai.js";
export default {
  data() {
    return {
      loading: null,
      dialogImageUrl: "", //回显图片
      dialogVisible: false,
      tarray: [], //图片数组
      images:[],//材料
      ruleForm: {
        state2: "", //标题
        wiehai: "", //类型
        pintai: "", //被举报平台
        neirong: "", //内容
        lianjie: "", //链接
        address: "",
      },
      options: [
        {
          label: "政治类",
          value: 0,
        },
        {
          label: "暴恐类",
          value: 1,
        },
        {
          label: "诈骗类",
          value: 2,
        },
        {
          label: "色情类",
          value: 3,
        },
        {
          label: "低俗类",
          value: 4,
        },
        {
          label: "赌博类",
          value: 5,
        },
        {
          label: "侵权类",
          value: 6,
        },
        {
          label: "谣言类",
          value: 7,
        },
        {
          label: "其他类",
          value: 8,
        },
      ],
      rules: {
        state2: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        wiehai: [{ required: true, message: "请选择类型", trigger: "blur" }],
        pintai: [
          { required: true, message: "请输入平台名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        neirong: [
          { required: true, message: "请描述您要举报问题", trigger: "blur" },
        ],
        lianjie: [
          { required: true, message: "请输入页面网址", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    let times = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  },
  methods: {
    chongzhi() {
      location.reload();
    },
    // 拖动上传限制
    xianzhi(file) {
      const isExcel =
        // file.name.split(".")[1] === "jpg" ||
        // file.name.split(".")[1] === "jpeg" ||
        // file.name.split(".")[1] === "png" ||
        file.name.split(".").pop() === "docx" ||
        file.name.split(".").pop() === "xlsx" ||
        file.name.split(".").pop() === "xls" ||
        file.name.split(".").pop() === "doc" ||
        file.name.split(".").pop() === "pdf";
      // const isSize = file.size / 1024 / 1024 < 1;
      if (!isExcel) {
        this.$message({
          message: "只能上传docx、xlsx、xls、docx、doc、pdf文件",
          type: "error",
        });
      }
      // if (!isSize) {
      //     this.$message({
      //         message: "上传文件大小不能超过 1MB!",
      //         type: "error"
      //     });
      // }
      // return isExcel && isSize;
      return isExcel;
    },
    beforeAvatarUpload(file) {
      // console.log(file.type,'问问');
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";

      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      // const is10M = file.size / 1024 / 1024 < 20;

      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("上传图片只能是 png,jpg,jpeg 格式!");
        return false;
      }

      //限制文件上传大小
      // if (!is10M) {
      //     this.$message.error("上传图片大小不能超过 20MB!");
      //     return false
      // }

      return true;
    },
    // 拖动上传删除
    shanchu(file, fileList) {
      let a = file.response.data.url;
      this.tarray.forEach((item, index) => {
        if (item == a) {
          this.tarray.splice(index, 1);
        }
      });
      // console.log(this.tarray, '拖动');
    },
    // 拖动上传
    erhao(response, file, fileList) {
      // console.log(response, file, fileList,'拖动');
      if (response.code == 1) {
        this.tarray.push(response.data.url);
      } else {
        this.$message({
          message: response.msg,
          center: true,
          type: "error",
        });
      }
    },
    chenggong(response, file, fileList) {
      // console.log(response, file, fileList,'2233');
      if (response.code == 1) {
        this.images.push(response.data.url);
      } else {
        this.$message({
          message: response.msg,
          center: true,
          type: "error",
        });
      }
      // console.log(this.tarray,'222');
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList, '111');
      let a = file.response.data.url;
      this.images.forEach((item, index) => {
        if (item == a) {
          this.images.splice(index, 1);
        }
      });
      // console.log(this.tarray, '233');
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async youhaixinxi() {
      let a = this.tarray.toString();
      let imagesarr=this.images.toString();
      // console.log(a);
      // return
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let data={
        title: this.ruleForm.state2,
        hazard: this.ruleForm.wiehai,
        platform: this.ruleForm.pintai,
        content: this.ruleForm.neirong,
        linkname: this.ruleForm.lianjie,
        address: this.ruleForm.address,
        files: a,
        images:imagesarr
      }
      let res = await API.add(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            center: true,
            type: "success",
          });
          // 刷新页面
          let timer = setTimeout(() => {
            this.loading.close();
            location.reload();
          }, 2000);
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            center: true,
            type: "error",
          });
        }
      });
    },
    // 提交表单前验证
    tijiaoBtn(formName) {
      if (this.images.length == 0) {
        this.$message.error("请上传至少一张问题图片!");
        return;
      }
      this.$refs[formName].validate((valid) => {
        // console.log(valid, "什么");
        if (valid) {
          // 通过则打开弹窗
          this.youhaixinxi();
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.harmful {
  width: 1200px;
  padding: 20px 0 0 0;
}

.fontOne {
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #27262f;
  letter-spacing: 0;
  font-weight: 700;
}

.biaodan {
  background: #ffffff;
  border: 1px solid #78a6dc;
  padding: 45px 78px 7px 29px;
  margin-bottom: 20px;

  .biaotimu {
    :deep(.el-autocomplete) {
      width: 100%;
    }

    ::v-deep(.el-col-12) {
      width: 100%;
      height: 44px;
    }

    ::v-deep(.el-input__inner) {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border: 1px solid #d6dbe3;
      border-radius: 2px;

      // &::-webkit-input-placeholder {
      //     font-family: PingFangSC-Regular;
      //     font-size: 15px;
      //     color: #484C51;
      //     letter-spacing: 0;
      //     font-weight: 400;
      // }
    }

    .tishi {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #c90e0e;
      letter-spacing: 0;
      font-weight: 400;
      margin-left: 18px;
      position: relative;
      display: flex;
      width: 200px;

      .tips {
        position: absolute;
        left: 0;
        bottom: 0;
        line-height: normal;
      }
    }
  }
}

.tuodong {
  margin-top: 20px;
  padding: 0 0 0 50px;
}

.anniu {
  margin-top: 20px;
  padding: 0 0 0 150px;
  margin-bottom: 20px;
}

:deep(.el-form-item__label) {
  min-width: 150px;
  white-space: nowrap;
}

:deep(.el-form-item__error) {
  left: 50px;
}

:deep(.el-upload__tip) {
  color: #c90e0e;
  line-height: 16px;
}
</style>